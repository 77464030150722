import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Register/RegisterView.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/confirm-reset",
    name: "ConfirmResetPassword",
    component: () => import(/* webpackChunkName: "Login" */ "../views/ConfirmResetPassword.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "Login" */ "../views/ResetPassword.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/",
    redirect: "/account",
  },
  {
    path: "/account",
    name: "Account",
    component: () => import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/:account_id",
    name: "admin",
    component: () => import(/* webpackChunkName: "Account" */ "../views/Main.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/:account_id/checkout",
    name: "checkout",
    component: () => import(/* webpackChunkName: "Account" */ "@/views/Checkout/CheckoutView.vue"), 
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (router.app.$store.getters.loggedIn === false) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (router.app.$store.getters.loggedIn === true) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
